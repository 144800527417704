.error-upload {
  border-color: red;
}
.abs-red-txt {
  color: red;
  position: absolute;
  top: -20px;
  left: 0;
  font-size: 0.75rem;
  font-weight: 700;
}
