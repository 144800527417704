.navbar-full {
  position: absolute;
  width: 45vw;
  /* height: 60px; */
  z-index: 300;
}
nav {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  padding: 1.5rem 1rem;
  align-items: center;
}
.muj-img {
  width: 200px;
  height: 100%;
  object-fit: cover;
}
li {
  list-style-type: none;
}
.nav-items {
  display: flex;
  font-weight: 600;
  font-size: 1.2rem;
  letter-spacing: 2px;
  margin-bottom: 0;
}
.nav-items li {
  margin-left: 2rem;
  cursor: pointer;
}
