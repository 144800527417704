.home {
  display: flex;
  background: rgba(225, 84, 20, 1);
}

.layer {
  background-color: white;
  width: 75%;
}

.graduationLogo {
  height: 300px;
}

.inputs {
  border: none !important;
  background: #e6e6e6 !important;
  transition: all 0.4s linear;
  padding: 30px !important;
  margin: 0 auto;
  width: 400px !important;
}

.form-control {
  display: block;
  height: calc(1.5em + 0.75rem + 2px);
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  border-radius: 0.25rem;
}

.text-Box {
  display: block;
}

.btn:not(:disabled):not(.disabled) {
  cursor: pointer;
}

.btn {
  display: inline-block;
  font-weight: 400;
  vertical-align: middle;
  user-select: none;
  color: #fff;
  background-color: #5a6268;
  border-color: #545b62;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.success-btn:not(:disabled):not(.disabled) {
  cursor: pointer;
}

.success-btn:hover {
  color: green !important;
  background-color: #fff !important;
  border-color: #1e7e34;
}

.success-btn {
  color: white;
  background-color: #1e7e34;
  width: 400px !important;
  padding: 16px !important;
  margin: 0 auto;
  border-radius: 50px !important;
  transition: all 0.5s linear;
  font-weight: 400;
  text-align: center;
  vertical-align: middle;
  border: 1px solid transparent;
  font-size: 1rem;
  line-height: 1.5;
  display: inline-block;
}

.form {
  text-align: center !important;
  flex: 0 0 58.333333%;
  max-width: 58.333333%;
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

.form-heading {
  text-transform: uppercase;
  letter-spacing: 0.25rem;
  font-weight: 700;
  font-size: 25px;
}

.form-group {
  margin-bottom: 1rem;
}

.detailsPage {
  display: block !important;
  text-align: center !important;
  flex: 0 0 41.666667%;
  max-width: 41.666667%;
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

.Dash-Upper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.table {
  text-align: center !important;
  flex: 0 0 100%;
  max-width: 100%;
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

.Form-outer {
  padding: 20px;
  border-radius: 12px;
  position: relative;
  margin: 150px 10%;
  width: 80%;
  background: linear-gradient(90deg, #ed764b, #eba51e 28%, #f7e9b3 71%);
}

.Form-inner {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: initial;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
}

.Form-Header {
  text-align: center;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
}

.vector {
  width: 15%;
  height: 10%;
}

.LOGONEW {
  /* width: 80%; */
  height: auto;
  /* margin: 10% 5% 10% 12%; */
}
